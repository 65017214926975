import services from "../../axios/dbManag";
import { msalInstance } from "@/authConfig";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import router from "@/router/index";

const getAlertDocTutoreResponsabileApi = (item_Id) => {
  return services.dbManag
    .post("/bacheca/documenti/tutore-responsabile/alert", {
      item_Id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => e.response);
};

const getMenuApi = async (id) => {
  return await services.baseApi
    .post("/menu/get", {
      id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

const getDefaultState = () => {
  return {
    menu: [],
    isMenuLoaded: false,
    errorMenu: false,
    idMenu: 0,
    status: 0,
    comandi_enabled: [],
    richiestaInCorso: false,
    puoAccedereBacheca: true,
    testoAlert: "",
    pagineAutorizzate: [],
  };
};

const state = getDefaultState();

const getters = {
  testoAlert: (state) => state.testoAlert,
  menu: (state) => state.menu,
  idMenu: (state) => state.idMenu,
  isMenuLoaded: (state) => state.isMenuLoaded,
  errorMenu: (state) => state.errorMenu,
  statusMenu: (state) => state.statusMenu,
  comandi_enabled: (state) => state.comandi_enabled,
  puoAccedereBacheca: (state) => state.puoAccedereBacheca,
  pagineAutorizzate: (state) => state.pagineAutorizzate,
};
const actions = {
  // chiamata all'inizio e con i filtri
  getMenuFromApi: async (stateObj, id) => {
    const { state } = stateObj;
    if (
      state.richiestaInCorso ||
      router.currentRoute._value.fullPath.includes("/verifica-tessera")
    )
      return;
    state.richiestaInCorso = true;
    state.errorMenu = false;
    stateObj.commit("setIdMenu", id);
    const res = await getMenuApi(state.id);
    const resAlert = await getAlertDocTutoreResponsabileApi();
    if (res.status == 200) {
      stateObj.commit("setMenu", res);
      stateObj.commit("verificaMenu");
    } else {
      if (res.data.statusCode && res.data.statusCode != 525) {
        const idTokenHint = localStorage.getItem("tokenAdb2c");
        await msalInstance.logoutRedirect({
          idTokenHint: idTokenHint,
        });
      }
    }
    stateObj.commit("setAlert", resAlert);
    return "Menu";
  },
};
const mutations = {
  setIdMenu: (state, id) => {
    state.idMenu = id;
  },
  setAlert: (state, res) => {
    if (
      res.status != 200 &&
      res.status != 401 &&
      res.status != 500 &&
      res.data.message != "Utente non autorizzato"
    ) {
      state.testoAlert = res.data.message;
      if (state.testoAlert) {
        setTimeout(() => {
          document.getElementById("openComunicazione").click();
        }, 1000);
      }
    }
  },
  setMenu: (state, res) => {
    if (res.data.statusCode === 525) return;
    if (res === undefined || res.status != 200) {
      Swal.fire({
        html: res.data.message
          ? res.data.message
          : "Attenzione! Si è verificato un errore. Riprovare più tardi",
        icon: "error",
        confirmButtonText: "Chiudi",
        width: 400,
        showCancelButton: false,
        // cancelButtonText: "Chiudi",
        confirmButtonColor: "#F1416C",
      }).then(async (result) => {
        if (result.isConfirmed) {
          localStorage.clear();
          if (!window.location.href.includes("logout")) {
            // await msalInstance.logoutRedirect({
            //   idTokenHint: idTokenHint,
            // });
          }
        }
      });
    }
    const { data, status } = res;
    data.menu.forEach((voice) => {
      state.menu.push(voice);
      if (voice.comandi_enabled.length)
        state.comandi_enabled = voice.comandi_enabled;
    });
    state.pagineAutorizzate = data.pagine_abilitate;
    state.statusMenu = status;
  },
  resetMenu: (state) => {
    Object.assign(state, getDefaultState());
  },

  verificaMenu: (state) => {
    state.menu.forEach((page) => {
      state.puoAccedereBacheca = page.pages.some(
        (page) => page.route === "/bacheca"
      );
    });
    localStorage.setItem("canAB", state.puoAccedereBacheca);

    if (localStorage.getItem("goToFch") == "0") {
      router.push("/404");
      state.isMenuLoaded = true;
      return;
    }
    if (
      router.currentRoute.value.fullPath === "/" ||
      router.currentRoute.value.fullPath === "/sign-in" ||
      router.currentRoute.value.fullPath.includes(
        process.env.VUE_APP_TEXT ?? ""
      )
    ) {
      router.push(state.menu[0].pages[0].route);
    }

    const autorizzatoVisitarePagina = state.pagineAutorizzate.some((pagina) => {
      return pagina
        .replace(/\/:[^/]+/g, "")
        .startsWith(`/${router.currentRoute.value.fullPath.split("/")[1]}`);
    });
    if (
      !router.currentRoute.value.fullPath.includes(
        process.env.VUE_APP_TEXT ?? ""
      ) &&
      !autorizzatoVisitarePagina
    ) {
      router.push("/non-autorizzato");
    }

    state.isMenuLoaded = true;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
