const getDefaultState = () => {
  return {
    rowsToSkipCampiPickleball: 0,
    fetchRowsCampiPickleball: 25,
    currentPageCampiPickleball: 1,
    sortColumnCampiPickleball: "id",
    sortOrderCampiPickleball: "desc",
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipCampiPickleball: (state) => state.rowsToSkipCampiPickleball,
  fetchRowsCampiPickleball: (state) => state.fetchRowsCampiPickleball,
  currentPageCampiPickleball: (state) => state.currentPageCampiPickleball,
  sortColumnCampiPickleball: (state) => state.sortColumnCampiPickleball,
  sortOrderCampiPickleball: (state) => state.sortOrderCampiPickleball,
};

// const actions = {};
const mutations = {
  setFetchRowsCampiPickleball: (state, value) => {
    state.currentPageCampiPickleball = 1;
    state.rowsToSkipCampiPickleball = 0;
    state.fetchRowsCampiPickleball = value;
  },
  setCurrentPageCampiPickleball: (state, value) => {
    state.currentPageCampiPickleball = value;
    state.rowsToSkipCampiPickleball = parseInt(
      (state.currentPageCampiPickleball - 1) * state.fetchRowsCampiPickleball
    );
  },
  setSortColumnCampiPickleball: (state, value) => {
    state.sortColumnCampiPickleball = value;
  },
  setSortOrderCampiPickleball: (state, value) => {
    state.sortOrderCampiPickleball = value;
  },
  resetFiltersCampiPickleball: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipCampiPickleball: (state) => {
    state.currentPageCampiPickleball = 1;
    state.rowsToSkipCampiPickleball = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
