const BACHECA_LIST = "/bacheca/documenti/list";

const COD_FISC_SOCIETA = "/anagrafiche/societa/check/codice_fiscale_valido";
const COD_FISC_PERSONE = "/anagrafiche/persone/check/codice_fiscale_valido";
const COMBOLIST_GET = "/combolist/get";
const COMUNI_ISTAT_LIST = "lookup/comuni/istat/list";
const COMUNI_NASCITA_LIST = "lookup/comuni/nascita/list";
const REPORTS_LIVELLI_LIST = "/lookup/reports/livelli";
// persone
const PERSONE_LIST = "/anagrafiche/persone/list";
//affiliazioni secondarie
const AFFILIAZIONI_SECONDARIE_LIST = "/anagrafiche/societa/secondaria/list";
// societa
const SOCIETA_LIST_GET = "/anagrafiche/societa/list";
const SOCIETA_ECONOMATO_LIST = "/economato/societa/movimenti/list";
const SOCIETA_SANZIONI_LIST = "/sanzioni/societa/sanzioni/list";
const SOCIETA_RICHIESTE_LIST = "/tesseramento/societa/richieste/list";
const SOCIETA_PRIVACY_LIST = "/anagrafiche/societa/persone/privacy/list";
const SOCIETA_TRASFERIMENTI_LIST = "/tesseramento/societa/trasferimenti/list";
const SCUOLE_SOCIETA_RICHIESTE = "/scuole/societa/richieste/list";
const SCUOLE_VIEW_RICHIESTA = "/scuole/richieste/view";
const DOCUMENTI_SOCIETA = "/affiliazioni/societa/documenti/list";
const PERMESSI_SOC_LOOKUP = "/documenti/acl/list";
const LOOKUP_COMITATI = "/lookup/comitati/list";

//impianti
const IMPIANTI_LIST = "/anagrafiche/impianti/list";
const OMOLOGAZIONI_LIST = "/omologazioni/impianti/list";
const IMPIANTI_ASSEGNA_LIST = "/affiliazioni/societa/impianti/selezione/list";
const IMPIANTO_CAMPI_PICKLEBALL_LIST =
  "/affiliazioni/societa/impianti/campi-pickleball/list";

//omologazioni
const OMOLOGAZIONI_ALL_LIST = "/omologazioni/list";

// tesseramento
const TESSERATI_SOCIETA_LIST = "/tesseramento/societa/tesserati/list";
const TESSERATI_RINNOVI_LIST = "/tesseramento/societa/rinnovi/list";
const TESSERATI_FEDERALI_LIST = "/tesseramento/tesserati-federali/list";
const TIPO_PAGAMENTI_FED_LOOKUP =
  "/lookup/tesseramento/speciali/pagamento/tipo/list";
const TESSERAMENTO_TIPOLOGIE = "/lookup/tesseramento/tipologie/list";
const TESSERAMENTO_QUALIFICHE = "/lookup/tesseramento/qualifiche/list";
const TESSERAMENTO_LIVELLI = "/lookup/tesseramento/livelli/list";
const TRASFERIMENTI_LIST = "/tesseramento/trasferimenti/list";
const TRASFERIMENTI_RICHIESTE_LIST =
  "/tesseramento/trasferimenti/richieste/list";
const VIVAIO_LIST = "/tesseramento/vivaio/list";

// economato
const ECONOMATO_AFFILIAZIONI_LIST = "/economato/report/affiliazioni/list";
const ECONOMATO_TESSERAMENTI_LIST = "/economato/report/tesseramenti/list";
const ECONOMATO_PAGAMENTI_LIST = "/economato/report/pagamenti/list";
const ECONOMATO_MOVIMENTI_LIST = "/economato/report/movimenti/list";
const ECONOMATO_SANZIONI_LIST = "/economato/report/sanzioni/list";
const ECONOMATO_TESSERE_SPECIALI_LIST =
  "/economato/report/tessere-speciali/list";
const ECONOMATO_TESSERE_GOLD_LIST =
  "/economato/report/movimenti/tessere/gold/list";
const ECONOMATO_SALDI_LIST = "/economato/report/saldi/list";
const ECONOMATO_COMPENSAZIONI_LIST = "/economato/report/compensazioni/list";
const LOOKUP_ECONOMATO_QUOTE = "/lookup/quote/list";
const LOOKUP_ECONOMATO_QUOTE_INSEGNANTI = "/lookup/quote/insegnanti/list";
const LOOKUP_TIPO_PAGAMENTI_SOCIETA =
  "/lookup/economato/pagamento/tipo/uso/societa";

// Utenti
const UTENTI_SOCIETA_LIST = "/sistema/utenti/list";
const UTENTI_PERSONE_LIST = "/sistema/utenti/list";
const UTENTI_COMITATI_LIST = "/sistema/utenti/list";
const UTENTI_OMOLOGATORI_LIST = "/sistema/utenti/list";

// Comitati
const COMITATI_LIST = "/sistema/comitati/list";
const COMITATO_UTENTI_LIST = "/sistema/comitato/utenti/list";

//albo\
const ALBO_LIST = "/albi/persone/qualifiche/list";
const ALBO_LIST_SNM = "/albi/persone/qualifiche/snm/list";

//ATTI
const ATTI_SOCIETA_GET = "/anagrafiche/societa/atti/view";
const LOOKUP_ATTO_TIPO_GET = "/lookup/anagrafiche/societa/tipo-forma/list";

//ranking
const LOOKUP_TESSERAMENTO_RANKING_GRUPPI_GET =
  "/lookup/tesseramento/ranking/list";
const RANKING_LIST = "/tesseramento/ranking/list";

//snm
const LOOKUP_TIPO_SCUOLE = "/lookup/scuole/tipo/list";
const SCUOLE_LIST = "/scuole/richieste/list";
const CORSI_LIST = "/snm/corsi/list";
const CORSI_AREA_INSEGNANTE_LIST = "/snm/corsi/aggiornamento/personale/list";
const LOOKUP_SPECIALIZZAZIONE_CORSI = "/snm/corsi/sotto-tipo/list";
const CORSO_PARTECIPANTI_LIST = "/snm/corsi/partecipanti/list";
const PARTECIPANTE_DOC_LIST = "/snm/corsi/partecipanti/documenti/list";
const ECONOMATO_SNM_LIST = "/economato/snm/list";
const ALBO_INSEGNANTE_LIST = "/albi/persone/qualifiche/personale/snm/list";
const RICHIESTA_BIGLIETTI = "/snm/richiesta/biglietti";

//sanzioni
const SANZIONI_LIST = "/sanzioni/procedimenti/list";
const LOOKUP_STATO_SANZIONI_FILTER = "/lookup/sanzioni/stato/list";
const LOOKUP_SANZIONI_ORGANI = "/lookup/sanzioni/organi/list";

//luoghi
const LUOGHI_PROVINCE_ISTAT = "/lookup/istat/province/list";

//download/upload documenti societa
const AGGIUNGI_DOCUMENTO_AFFILIAZIONE_SOCIETA =
  "/affiliazioni/societa/documenti/add";
const EDIT_DOCUMENTO_AFFILIAZIONE_SOCIETA =
  "/affiliazioni/societa/documenti/edit";

const LISTA_DOCUMENTI_PERSONA = "/anagrafiche/persone/documenti/list";
const AGGIUNGI_DOCUMENTO_PERSONA = "/anagrafiche/persone/documenti/add";
const EDIT_DOCUMENTO_PERSONA = "/anagrafiche/persone/documenti/edit";

const AGGIUNGI_DOCUMENTO_BACHECA = "/bacheca/documenti/add";
const EDIT_DOCUMENTO_BACHECA = "/bacheca/documenti/edit";

const AGGIUNGI_DOCUMENTO_CORSI = "/snm/corsi/documenti/add";
const EDIT_DOCUMENTO_CORSI = "/snm/corsi/documenti/edit";

const AGGIUNGI_DOCUMENTO_CORSI_PARTECIPANTI =
  "/snm/corsi/partecipanti/documenti/add";

// discipline impianti
const DISCIPLINE_IMPIANTI = "/lookup/discipline/impianto/list";

//esportazioni
const ESPORTAZIONI_LIST = "/esportazioni/richieste/list";

const LISTA_DOCUMENTI_OMOLOGAZIONI = "/omologazioni/impianti/documenti/list";
const ADD_DOCUMENTO_OMOLOGAZIONI = "/omologazioni/impianti/documenti/add";
const EDIT_DOCUMENTO_OMOLOGAZIONI = "/omologazioni/impianti/documenti/edit";

//importazioni
const IMPORTAZIONI_LIST = "/importazioni/richieste/list";
const IMPORTAZIONI_DETTAGLIO_LIST =
  "/importazioni/richieste/dettaglio/tesserati/list";

const IMPORTAZIONI_BATCH = "/importazioni/richieste/add";
const TIPOLOGIE_BATCH_LIST = "/importazioni/richieste/tipologie/list";

//helpdesk-pretesseramento
const PRETESSERAMENTO_ANAGRAFICHE_LIST = "/pre-tesseramento/anagrafiche/list";
const PRETESSERAMENTO_RICHIESTE_LIST =
  "/pre-tesseramento/richieste/tesseramento/list";
const REPORT_PRETESSERAMENTO_LIST = "/pre-tesseramento/conteggi/dettaglio";

//FIT-report
const REPORTS_LIST = "/reportistica/economato/report/view";

//gestione_quote
const GESTIONE_QUOTE_LIST = "/reportistica/configurazione/causali/list";

//raas
const RAAS_AFFILIAZIONI = "/raas/affiliazioni/list";
const RAAS_CONSIGLIO_DIRETTIVO = "/raas/consiglio-direttivo/list";
const RAAS_DOCUMENTI = "/raas/documenti/list";
const RAAS_TESSERAMENTI = "/raas/tesseramenti/list";

const RAAS_SPORT = "/raas/attivita-sportiva/list";
const RAAS_DIDATTICA = "/raas/attivita-didattica/list";
const RAAS_FORMAZIONE = "/raas/attivita-formativa/list";
// raas errori
const RAAS_ERR_AFFILIAZIONI = "/raas/affiliazioni/errori/log/list";
const RAAS_ERR_CONSIGLIO_DIRETTIVO =
  "/raas/consiglio-direttivo/errori/log/list";
const RAAS_ERR_DOCUMENTI = "/raas/documenti/errori/log/list";
const RAAS_ERR_TESSERAMENTI = "/raas/tesseramenti/errori/log/list";
const RAAS_ERR_SPORT = "/raas/attivita-sportiva/errori/log/list";
const RAAS_ERR_DIDATTICA = "/raas/attivita-didattica/errori/log/list";
const RAAS_ERR_FORMAZIONE = "/raas/attivita-formativa/list";
// ATTESTATI
const SNM_CORSI_ATTESTATI_GENERA_LIST = "/snm/corsi/attestati/genera/list";
const SNM_CORSI_ATTESTATI_QUALIFICHE_LIST =
  "/snm/corsi/attestati-qualifiche/list";
const SNM_CORSI_DOCUMENTI_ADD = "/snm/corsi/documenti/add";
const SNM_CORSI_DOCUMENTI_DEL = "/snm/corsi/documenti/del";
const SNM_CORSI_DOCUMENTI_EDIT = "/snm/corsi/documenti/edit";
const SNM_CORSI_DOCUMENTI_LIST = "/snm/corsi/documenti/list";
const SNM_CORSI_DOCUMENTI_VIEW = "/snm/corsi/documenti/view";

const SNM_CORSI_PERSONE_GESTIONE_ATTESTATI_LIST =
  "/snm/corsi/persone/gestione-attestati/list";
const SNM_CORSI_PERSONE_GESTIONE_ATTESTATI_DEL =
  "/snm/corsi/persone/gestione-attestati/del";

const ALBI_PERSONE_QUALIFICHE_DOWNLOAD_DISPONIBILE_ADD =
  "/albi/persone/qualifiche/download-disponibile/add";

const ALBI_PERSONE_QUALIFICHE_GENERA_LIST =
  "/albi/persone/qualifiche/genera/list";

const ANAGRAFICHE_ENTI_AGGREGATI_LIST = "/anagrafiche/enti-aggregati/list";
const ANAGRAFICHE_ENTI_AGGREGATI_VIEW = "/anagrafiche/enti-aggregati/view";
const AFFILIAZIONI_ENTI_AGGREGATI_IMPIANTI_SELEZIONE_LIST =
  "/affiliazioni/enti-aggregati/impianti/selezione/list";
const AFFILIAZIONI_ENTI_AGGREGATI_DOCUMENTI_LIST =
  "/affiliazioni/enti-aggregati/documenti/list";

const AGGIUNGI_DOCUMENTO_AFFILIAZIONE_ENTE =
  "/affiliazioni/enti-aggregati/documenti/add";

const AGGIUNGI_DOCUMENTO_RICHIESTA_IMPORTAZIONE =
  "/importazioni/richieste/societa/add";

const EDIT_DOCUMENTO_AFFILIAZIONE_ENTE =
  "/affiliazioni/enti-aggregati/documenti/edit";

const ENTI_AGGREGATI_PRIVACY_LIST =
  "/anagrafiche/enti-aggregati/persone/privacy/list";

const VERIFICA_ANOMALIE_LIST = "/verifiche/procedura-verifica/get";
const RICHIESTA_IMPORTAZIONI_LIST = "/importazioni/richieste/societa/list";
const RICHIESTA_IMPORTAZIONI_EDIT = "/importazioni/richieste/societa/edit";
const RICHIESTA_IMPORTAZIONI_DEL = "/importazioni/richieste/societa/delete";
const apiList = {
  RICHIESTA_IMPORTAZIONI_DEL,
  RICHIESTA_IMPORTAZIONI_EDIT,
  AGGIUNGI_DOCUMENTO_RICHIESTA_IMPORTAZIONE,
  RICHIESTA_IMPORTAZIONI_LIST,
  VERIFICA_ANOMALIE_LIST,
  ENTI_AGGREGATI_PRIVACY_LIST,
  EDIT_DOCUMENTO_AFFILIAZIONE_ENTE,
  AGGIUNGI_DOCUMENTO_AFFILIAZIONE_ENTE,
  AFFILIAZIONI_ENTI_AGGREGATI_DOCUMENTI_LIST,
  AFFILIAZIONI_ENTI_AGGREGATI_IMPIANTI_SELEZIONE_LIST,
  ANAGRAFICHE_ENTI_AGGREGATI_VIEW,
  ANAGRAFICHE_ENTI_AGGREGATI_LIST,
  ALBI_PERSONE_QUALIFICHE_GENERA_LIST,
  ALBI_PERSONE_QUALIFICHE_DOWNLOAD_DISPONIBILE_ADD,
  SNM_CORSI_ATTESTATI_GENERA_LIST,
  SNM_CORSI_ATTESTATI_QUALIFICHE_LIST,
  SNM_CORSI_DOCUMENTI_ADD,
  SNM_CORSI_DOCUMENTI_DEL,
  SNM_CORSI_DOCUMENTI_EDIT,
  SNM_CORSI_DOCUMENTI_LIST,
  SNM_CORSI_DOCUMENTI_VIEW,

  RAAS_ERR_SPORT,
  RAAS_ERR_DIDATTICA,
  RAAS_ERR_FORMAZIONE,
  RAAS_ERR_AFFILIAZIONI,
  RAAS_ERR_CONSIGLIO_DIRETTIVO,
  RAAS_ERR_DOCUMENTI,
  RAAS_ERR_TESSERAMENTI,
  RAAS_SPORT,
  RAAS_DIDATTICA,
  RAAS_FORMAZIONE,
  RAAS_AFFILIAZIONI,
  RAAS_CONSIGLIO_DIRETTIVO,
  RAAS_DOCUMENTI,
  RAAS_TESSERAMENTI,
  COD_FISC_SOCIETA,
  COD_FISC_PERSONE,
  COMBOLIST_GET,
  REPORTS_LIVELLI_LIST,
  COMUNI_ISTAT_LIST,
  COMUNI_NASCITA_LIST,
  AFFILIAZIONI_SECONDARIE_LIST,
  SOCIETA_LIST_GET,
  TESSERATI_SOCIETA_LIST,
  TESSERATI_FEDERALI_LIST,
  TESSERAMENTO_TIPOLOGIE,
  TESSERAMENTO_QUALIFICHE,
  TESSERAMENTO_LIVELLI,
  LOOKUP_TESSERAMENTO_RANKING_GRUPPI_GET,
  ATTI_SOCIETA_GET,
  LOOKUP_ATTO_TIPO_GET,
  TESSERATI_RINNOVI_LIST,
  VIVAIO_LIST,
  RANKING_LIST,
  LOOKUP_TIPO_SCUOLE,
  CORSI_LIST,
  CORSI_AREA_INSEGNANTE_LIST,
  SCUOLE_SOCIETA_RICHIESTE,
  CORSO_PARTECIPANTI_LIST,
  PARTECIPANTE_DOC_LIST,
  SANZIONI_LIST,
  LOOKUP_SANZIONI_ORGANI,
  DOCUMENTI_SOCIETA,
  LOOKUP_SPECIALIZZAZIONE_CORSI,
  SCUOLE_VIEW_RICHIESTA,
  SOCIETA_ECONOMATO_LIST,
  LUOGHI_PROVINCE_ISTAT,
  SOCIETA_SANZIONI_LIST,
  SOCIETA_RICHIESTE_LIST,
  SOCIETA_PRIVACY_LIST,
  SOCIETA_TRASFERIMENTI_LIST,
  BACHECA_LIST,
  TRASFERIMENTI_LIST,
  IMPIANTI_LIST,
  ALBO_LIST,
  PERMESSI_SOC_LOOKUP,
  ECONOMATO_AFFILIAZIONI_LIST,
  ECONOMATO_TESSERAMENTI_LIST,
  ECONOMATO_PAGAMENTI_LIST,
  ECONOMATO_MOVIMENTI_LIST,
  ECONOMATO_SANZIONI_LIST,
  ECONOMATO_TESSERE_SPECIALI_LIST,
  ECONOMATO_TESSERE_GOLD_LIST,
  ECONOMATO_SALDI_LIST,
  ECONOMATO_COMPENSAZIONI_LIST,
  AGGIUNGI_DOCUMENTO_AFFILIAZIONE_SOCIETA,
  OMOLOGAZIONI_LIST,
  OMOLOGAZIONI_ALL_LIST,
  EDIT_DOCUMENTO_AFFILIAZIONE_SOCIETA,
  LISTA_DOCUMENTI_PERSONA,
  AGGIUNGI_DOCUMENTO_PERSONA,
  EDIT_DOCUMENTO_PERSONA,
  AGGIUNGI_DOCUMENTO_BACHECA,
  EDIT_DOCUMENTO_BACHECA,
  UTENTI_SOCIETA_LIST,
  UTENTI_PERSONE_LIST,
  UTENTI_COMITATI_LIST,
  UTENTI_OMOLOGATORI_LIST,
  COMITATI_LIST,
  ECONOMATO_SNM_LIST,
  LOOKUP_ECONOMATO_QUOTE,
  LOOKUP_ECONOMATO_QUOTE_INSEGNANTI,
  TRASFERIMENTI_RICHIESTE_LIST,
  LOOKUP_STATO_SANZIONI_FILTER,
  LOOKUP_TIPO_PAGAMENTI_SOCIETA,
  AGGIUNGI_DOCUMENTO_CORSI,
  EDIT_DOCUMENTO_CORSI,
  DISCIPLINE_IMPIANTI,
  TIPO_PAGAMENTI_FED_LOOKUP,
  LOOKUP_COMITATI,
  COMITATO_UTENTI_LIST,
  AGGIUNGI_DOCUMENTO_CORSI_PARTECIPANTI,
  ESPORTAZIONI_LIST,
  LISTA_DOCUMENTI_OMOLOGAZIONI,
  ADD_DOCUMENTO_OMOLOGAZIONI,
  EDIT_DOCUMENTO_OMOLOGAZIONI,
  IMPORTAZIONI_LIST,
  IMPORTAZIONI_DETTAGLIO_LIST,
  IMPORTAZIONI_BATCH,
  TIPOLOGIE_BATCH_LIST,
  ALBO_LIST_SNM,
  ALBO_INSEGNANTE_LIST,
  PRETESSERAMENTO_ANAGRAFICHE_LIST,
  PRETESSERAMENTO_RICHIESTE_LIST,
  REPORT_PRETESSERAMENTO_LIST,
  PERSONE_LIST,
  IMPIANTI_ASSEGNA_LIST,
  REPORTS_LIST,
  GESTIONE_QUOTE_LIST,
  SCUOLE_LIST,
  RICHIESTA_BIGLIETTI,
  SNM_CORSI_PERSONE_GESTIONE_ATTESTATI_LIST,
  SNM_CORSI_PERSONE_GESTIONE_ATTESTATI_DEL,
  IMPIANTO_CAMPI_PICKLEBALL_LIST,
};

export default apiList;
